import clsx from 'clsx';
import React from 'react';
import { Button, ButtonRounding, Props } from '@/components/button/Button';
import { Icon, IconName } from '@/components/icon/Icon';
import { dangerButtonClassnames } from '@/components/button/DangerButton';
import { subtleDangerButtonClassnames } from '@/components/button/SubtleDangerButton';
import { lowImpactButtonClassNames } from './LowImpactButton';
import { subtleButtonClassNames } from './SubtleButton';
import { highImpactButtonClassNames } from './HighImpactButton';

type IconOnlyButtonProps = Omit<Props, 'prefixIcon' | 'suffixIcon' | 'label' | 'variant'> & {
  icon: IconName;
  variant?:
    | 'default'
    | 'default-borderless'
    | 'danger'
    | 'subtle-danger'
    | 'subtle-danger-borderless'
    | 'low-impact'
    | 'subtle'
    | 'high-impact';
  rounding?: ButtonRounding;
};

export const IconOnlyButton = React.forwardRef<HTMLButtonElement, IconOnlyButtonProps>(
  (
    {
      variant = 'default',
      loading = false,
      icon,
      size = 'lg',
      className,
      rounding = 'pill',
      ...props
    },
    ref
  ) => {
    const isLarge = size === 'lg';
    const iconSize = isLarge ? 'h-7 w-7' : 'h-4 w-4';

    return (
      <Button
        iconOnly={true}
        ref={ref}
        {...props}
        size={size}
        rounding={rounding}
        className={clsx(
          className,
          'group aspect-square',
          variant === 'default' && [
            size === 'lg' ? 'border-2' : 'border',
            'border-gray-90 bg-white',
            'focus:active:outline-none',
            hover('bg-functional-on-light-interactive border-emerald'),
            active(
              'border-cyan-20 bg-gradient-to-tr from-emerald to-rose text-cyan-20 shadow-glitch-small dk:from-violet-20 dk:to-sapphire dk:text-blue-80'
            ),
            disabled(
              'border-gray-90 bg-non-interactive-on-light bg-none text-gray-90 shadow-none text-shadow-none'
            ),
            focus('outline-none dk:shadow-focus-dark')
          ],
          variant === 'default-borderless' && [
            'text-black dk:text-white',
            'focus:outline-black dk:focus:outline-white',
            hover('text-emerald dk:text-violet-40'),
            active(
              'text-black outline-none drop-shadow-glitch-small dk:text-white dk:outline-none'
            ),
            disabled(
              'text-non-interactive-on-dark drop-shadow-none dk:text-non-interactive-on-light'
            )
          ],
          variant === 'danger' && [
            ...dangerButtonClassnames,
            'border border-transparent',
            active('border border-functional-on-light-error bg-red-20'),
            disabled('border outline-2 outline-offset-0 outline-red-90 active:!border-red-90')
          ],
          variant === 'subtle-danger' && [
            ...subtleDangerButtonClassnames,
            'border border-red-90',
            focus('border-transparent'),
            hover('border-functional-on-light-error'),
            active('border border-functional-on-light-error !bg-red-20 !text-white'),
            disabled(
              'border !border-red-90 !bg-non-interactive-on-light !text-red-70 shadow-none outline-2 outline-offset-0 outline-red-90'
            )
          ],
          variant === 'subtle-danger-borderless' && [
            ...subtleDangerButtonClassnames,
            'text-red-30 dk:text-red-40',
            hover('!bg-transparent'),
            active('drop-shadow-glitch-small'),
            disabled('text-non-interactive-on-dark drop-shadow-none')
          ],
          variant === 'low-impact' && lowImpactButtonClassNames,
          variant === 'subtle' && subtleButtonClassNames,
          variant === 'high-impact' && highImpactButtonClassNames
        )}
      >
        <Icon
          icon={loading ? 'buffer' : icon}
          className={clsx(
            iconSize,
            'fill-current',
            loading && 'animate-spin',
            variant === 'default' && [
              'group-active:drop-shadow-glitch-small',
              'group-disabled:drop-shadow-none',
              'text-black dk:text-white',
              'group-hover:text-turquoise-10 group-hover:dk:text-violet-80',
              'group-active:text-cyan-20 group-active:text-shadow-glitch group-active:dk:text-blue-70',
              'group-disabled:text-gray-60 group-disabled:dk:text-gray-40'
            ]
          )}
        />
      </Button>
    );
  }
);
