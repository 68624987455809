import clsx from 'clsx';
import React from 'react';
import Logo from '@img/Logo148Black.png';
import { SubtleButton } from '@/components/button/SubtleButton';

export function HeaderPublic() {
  return (
    <div className={clsx('bg-white relative flex h-20 justify-between px-8 py-2')}>
      <div className="flex items-center">
        {/* PNG version of logo added here to help with the pixelation caused by svg version */}
        <img
          className="h-14 w-14"
          src={Logo}
          alt="Compass with a needle pointing north with the word Nettwerk underneath it"
        />
        <div className={clsx('ml-4 hidden md:flex md:items-center')}>
          <SubtleButton
            onDark={false}
            size="lg"
            rounding="square"
            external={false}
            href="/dashboard"
            label="Central"
          />
        </div>
      </div>
    </div>
  );
}
