import { useTheme } from '@/hooks/useTheme';
import React from 'react';

export function withTheme<T>(Component: React.ForwardRefRenderFunction<T, any>) {
  return React.forwardRef<T, any>((props, ref) => {
    const { onDark: isThemeDark } = useTheme();
    let { onDark, ...strippedProps } = props;

    // We do this to get around Storybocbok passing `onDark` as a mocked function
    onDark = typeof onDark === 'boolean' ? onDark : isThemeDark;

    return <Component onDark={onDark} {...strippedProps} ref={ref} />;
  });
}
