import React from 'react';
import * as Sentry from '@sentry/react';
import ErrorPageContent from './components/errors/ErrorPageContent';

export function ErrorBoundary({ children }: any) {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <ErrorPageContent
          status={500}
          title="There's been a glitch..."
          message="Something went wrong on our end. We know about this mistake and are working to fix it."
          buttonLabel="Go to the dashboard"
          buttonLink={route('dashboard')}
        />
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
