import React from 'react';

interface Props {
  className?: string;
}

export const Blob11 = ({ className }: Props) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1280 192"
      fill="none"
    >
      <path
        d="M1280 91.6712C1280 91.6712 1032.47 102.406 875.112 120.83C748.084 135.704 680 149.877 551.64 170.596C387.458 197.096 267.657 203.02 125.072 165.055C68.4335 149.975 2.95475e-05 114.707 2.95475e-05 114.707L1.67852e-05 7.62939e-05L1280 0.000111901L1280 91.6712Z"
        fill="#06031A"
      />
    </svg>
  );
};
