import clsx from 'clsx';
import { Button, ComposedButtonProps } from './Button';

export const IconEmphasisButton = ({
  label,
  className,
  size = 'lg',
  ...props
}: ComposedButtonProps) => (
  <Button
    {...props}
    size={size}
    variant="icon-emphasis"
    className={clsx(
      className,
      size === 'lg' ? 'hover:text-turquoise-30 active:shadow-glitch' : 'active:shadow-glitch-small',
      'text-black dk:text-white',
      hover('bg-interactive-on-light underline dk:bg-interactive-on-dark dk:text-violet-80'),
      focus('bg-white text-black outline-none dk:bg-black dk:text-white'),
      active(
        'bg-cyan-100 text-cyan-50 no-underline outline-0 text-shadow-glitch dk:bg-blue-10 dk:text-blue-50'
      ),
      disabled(
        'bg-transparent text-gray-60 no-underline opacity-80 shadow-none text-shadow-none dk:bg-transparent dk:text-gray-40'
      )
    )}
  >
    {label}
  </Button>
);
