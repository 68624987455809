import clsx from 'clsx';
import React from 'react';
import { Button, ComposedButtonProps } from './Button';

export const dangerButtonClassnames = [
  'bg-functional-on-light-error text-white dk:bg-functional-on-dark-error',
  'ring-offset-2',
  hover('bg-red-40 dk:bg-red-30'),
  focus(
    'bg-functional-on-light-error outline-none dk:bg-functional-on-dark-error dk:shadow-focus-dark'
  ),
  '[&:not(:disabled)]:active:outline-none',
  active('bg-red-20 text-white dk:bg-red-20'),
  disabled(
    'bg-red-90 text-red-60 !outline !outline-2 !outline-non-interactive-on-light/25 dk:bg-red-10 dk:text-red-30 dk:outline dk:outline-2 dk:outline-non-interactive-on-dark/25'
  )
];

export const DangerButton = React.forwardRef<HTMLButtonElement, ComposedButtonProps>(
  ({ label, className, size = 'lg', ...props }, ref) => (
    <Button
      ref={ref}
      {...props}
      size={size}
      variant="danger"
      className={clsx(className, dangerButtonClassnames)}
    >
      {label}
    </Button>
  )
);
