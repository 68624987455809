import clsx from 'clsx';
import React from 'react';
import { Text } from '@/@types/Text';

export type ActionTextVariant = '3xl' | 'xl' | 'lg' | 'emphasis' | 'base' | 'sm';

interface ActionTextProps extends Text {
  variant: ActionTextVariant;
  isInline?: boolean;
}

interface Props extends Text {
  isInline?: boolean;
}

export const ActionText3XL = (props: Props) => <ActionText {...props} variant="3xl" />;
export const ActionTextXL = (props: Props) => <ActionText {...props} variant="xl" />;
export const ActionTextLg = (props: Props) => <ActionText {...props} variant="lg" />;
export const ActionTextBaseEmphasis = (props: Props) => (
  <ActionText {...props} variant="emphasis" />
);
export const ActionTextBase = (props: Props) => <ActionText {...props} variant="base" />;
export const ActionTextSm = (props: Props) => <ActionText {...props} variant="sm" />;

export function ActionText({
  children,
  variant,
  className,
  isInline = false,
  as: Component = 'span',
  ...props
}: ActionTextProps) {
  return (
    <Component
      className={clsx(
        variant === '3xl' && 'text-3xl font-medium tracking-inter-tighter',
        variant === 'xl' &&
          `text-xl font-medium tracking-inter-tighter ${isInline ? 'leading-7' : 'leading-8'}`,
        variant === 'lg' && 'text-lg font-medium leading-6',
        variant === 'emphasis' &&
          `text-base font-medium lg:text-lg ${isInline ? 'leading-4 lg:leading-6' : 'leading-5 lg:leading-6'}`,
        variant === 'base' && `text-base font-medium ${isInline ? 'leading-4' : 'leading-5'}`,
        variant === 'sm' && `text-sm font-medium ${isInline ? 'leading-3.5' : 'leading-4'}`,
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
}
