import clsx from 'clsx';
import { Button, ComposedButtonProps } from './Button';

export const DefaultButton = ({ label, className, size = 'lg', ...props }: ComposedButtonProps) => (
  <Button
    {...props}
    size={size}
    variant="default"
    className={clsx(
      className,
      size === 'lg' ? 'active:shadow-glitch' : 'active:shadow-glitch-small',
      'bg-black text-white dk:bg-white dk:text-black',
      'ring-offset-2',
      hover('bg-emerald text-turquoise-10 dk:bg-amethyst dk:text-violet-100'),
      focus('bg-black text-emerald outline-none dk:bg-gray-90 dk:text-violet-10'),
      active(
        'bg-gradient-to-tr from-emerald to-rose text-cyan-20 outline-0 text-shadow-glitch dk:from-violet-20 dk:to-sapphire dk:text-blue-80'
      ),
      disabled(
        'bg-non-interactive-on-light/20 bg-none text-gray-50 opacity-80 shadow-none outline-2 outline-non-interactive-on-light text-shadow-none dk:bg-non-interactive-on-dark/20 dk:text-gray-50 dk:outline-non-interactive-on-dark'
      )
    )}
  >
    {label}
  </Button>
);
