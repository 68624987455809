import { Toast } from '@/@types/Toast';
import { create } from 'zustand';

interface ToastsState {
  toasts: Toast[];
  addToast: (toast: Toast) => void;
  addToasts: (toasts: Toast[]) => void;
  removeToast: (id: string) => void;
  resetToasts: () => void;
}

export default create<ToastsState>((set) => ({
  toasts: [],
  addToast: (toast: Toast) =>
    set((state) => ({
      toasts: [...state.toasts, toast]
    })),
  addToasts: (toasts: Toast[]) =>
    set((state) => ({
      toasts: [...state.toasts, ...toasts]
    })),
  removeToast: (id: string) =>
    set((state) => ({
      toasts: state.toasts.filter((toast) => toast.id !== id)
    })),
  resetToasts: () =>
    set(() => ({
      toasts: []
    }))
}));
