import React from 'react';

const iconImports = import.meta.glob('../../icons/*.svg', { as: 'raw', eager: true });

export const IconSpritesheet = React.memo(function () {
  const icons = Object.keys(iconImports)
    .map((icon) => {
      let svg: any = iconImports[icon] ?? null;
      if (!svg) {
        return null;
      }

      const id = icon.replace('../../icons/', '').replace('.svg', '');

      svg = svg.replace('<svg ', `<symbol id="icon-${id}" `);
      svg = svg.replace('</svg>', '</symbol>');

      return svg;
    })
    .join(' ');

  return (
    <svg
      dangerouslySetInnerHTML={{ __html: icons }}
      width="0"
      height="0"
      viewBox="0 0 32 32"
      fill="none"
      className="hidden"
      xmlns="http://www.w3.org/2000/svg"
    />
  );
});
