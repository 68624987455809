import React from 'react';
import clsx from 'clsx';
import { Button, ComposedButtonProps } from './Button';

export const lowImpactButtonClassNames = [
  'bg-interactive-on-light text-black dk:bg-interactive-on-dark dk:text-white',
  hover('bg-turquoise-100 text-turquoise-10 dk:bg-violet-10 dk:text-violet-80'),
  focus('bg-interactive-on-light text-black outline-none dk:bg-interactive-on-dark dk:text-white'),
  active('bg-cyan-90 text-cyan-30 outline-0 text-shadow-glitch dk:bg-blue-10 dk:text-blue-70'),
  disabled(
    'bg-non-interactive-on-light/20 text-gray-50 opacity-80 shadow-none text-shadow-none dk:bg-non-interactive-on-dark/20 dk:text-gray-50'
  )
];

export const LowImpactButton = React.forwardRef<HTMLButtonElement, ComposedButtonProps>(
  ({ label, className, size = 'lg', ...props }, ref) => (
    <Button
      ref={ref}
      {...props}
      size={size}
      variant="low-impact"
      className={clsx(
        className,
        size === 'lg' ? 'active:shadow-glitch' : 'active:shadow-glitch-small',
        lowImpactButtonClassNames
      )}
    >
      {label}
    </Button>
  )
);
