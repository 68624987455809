import clsx from 'clsx';
import React from 'react';
import { Button, ComposedButtonProps } from './Button';

export const subtleDangerButtonClassnames = [
  'text-functional-on-light-error outline-none dk:text-functional-on-dark-error dk:outline-none',
  hover(
    'bg-interactive-on-light/25 text-red-40 underline outline-none dk:bg-interactive-on-dark/25 dk:text-red-50 dk:outline-none'
  ),
  focus(
    'bg-interactive-on-light/25 text-functional-on-light-error outline-none dk:bg-black dk:text-functional-on-dark-error'
  ),
  active('bg-transparent text-red-20 no-underline outline-none dk:text-red-60 dk:outline-none'),
  disabled(
    'bg-transparent text-red-70 no-underline outline-none dk:bg-transparent dk:text-red-20 dk:outline-none'
  )
];

export const SubtleDangerButton = React.forwardRef<HTMLButtonElement, ComposedButtonProps>(
  ({ label, className, size = 'lg', ...props }, ref) => (
    <Button
      ref={ref}
      {...props}
      size={size}
      variant="subtle-danger"
      className={clsx(className, subtleDangerButtonClassnames)}
    >
      {label}
    </Button>
  )
);
