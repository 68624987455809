import clsx from 'clsx';
import React from 'react';

interface Props {
  text: string;
  theme?: 'light' | 'dark';
  className?: string;
}

export function HollowText({ text, className = '', theme = 'dark' }: Props) {
  return (
    <h1
      className={clsx(
        'relative font-bold font-poppins uppercase tracking-wide text-fill-transparent text-stroke md:text-stroke-2',
        theme === 'light' ? 'text-white text-stroke-[#FFF]' : 'text-amethyst text-stroke-amethyst',
        className
      )}
    >
      <span
        aria-hidden="true"
        className="absolute z-10 block -translate-y-[.5px] translate-x-[.5px] text-stroke-[#5FFAC4] md:-translate-y-[1px] md:translate-x-[1px]"
      >
        {text}
      </span>
      <span
        aria-hidden="true"
        className="absolute z-10 block -translate-x-[.5px] translate-y-[.5px] text-stroke-[#FF88ED] md:-translate-x-[1px] md:translate-y-[1px]"
      >
        {text}
      </span>
      <span className="relative z-20">{text}</span>
    </h1>
  );
}
