import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp, router } from '@inertiajs/react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

import '../css/app.css';
import { ErrorBoundary } from './ErrorBoundary';
import { Provider as WrapBalancerProvider } from 'react-wrap-balancer';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  integrations: [
    new CaptureConsoleIntegration({
      levels: ['error']
    }) as any,
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', window.location.host]
    }),
    new Sentry.Replay()
  ],
  sampleRate: 1.0,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const pages = import.meta.glob('./pages/**/*.tsx');
function resolveComponent(page: any) {
  const importPage = pages[`./pages/${page}.tsx`];

  if (!importPage) {
    throw new Error(`Unknown page ${page}. Is it located under Pages with a .tsx extension?`);
  }

  return importPage().then((module: any) => {
    module.default.layout = (page: React.ReactNode) => <ErrorBoundary>{page}</ErrorBoundary>;

    return module.default;
  });
}

createInertiaApp({
  resolve: resolveComponent,
  setup({ el, App, props }) {
    const root = createRoot(el!);
    root.render(
      <WrapBalancerProvider>
        <App {...props} />
      </WrapBalancerProvider>
    );
  },
  progress: {
    color: '#4B5563'
  }
});

router.on('navigate', () => window?.fathom?.trackPageview());
