import React from 'react';

interface Props {
  className?: string;
}

export const Blob12 = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 390 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M390 125.916C390 125.916 301.787 146.743 266.636 165.969C231.484 185.194 204.975 220.173 168.078 234.324C130.848 248.276 81.5516 278.861 38.1078 226.714C20.8508 206 1.555e-05 157.558 1.555e-05 157.558L2.23802e-05 7.62939e-05L390 3.40949e-05L390 125.916Z"
        fill="#06031A"
      />
    </svg>
  );
};
