import React, { useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import { Toast as ToastInterface } from '@/@types/Toast';
import useToasts from '@/hooks/useToasts';
import { Toast } from './Toast';

export function ToastManager() {
  const { toasts, addToasts, removeToast, resetToasts } = useToasts();
  const page = usePage();

  useEffect(() => {
    resetToasts();
  }, [resetToasts, page.url]);

  useEffect(() => {
    const pageToasts = (page.props.toasts || []) as ToastInterface[];

    if (pageToasts.length > 0) {
      addToasts(pageToasts);
    }
  }, [page.props.toasts, addToasts]);

  return (
    <div className="fixed left-0 right-0 top-0 z-70 m-2 space-y-4 md:left-auto md:max-w-md">
      {toasts.map((toast: ToastInterface) => (
        <Toast key={toast.id} toast={toast} removeToast={removeToast} />
      ))}
    </div>
  );
}
