import clsx from 'clsx';
import React from 'react';
import { Icon } from '@/components/icon/Icon';
import { useTheme } from '@/hooks/useTheme';
import { SubtleButton } from '@/components/button/SubtleButton';
import { LowImpactButton } from '../button/LowImpactButton';
import { Popover } from '@headlessui/react';

interface Props {
  className?: string;
  isOpen: boolean;
  area?: string;
}

export function Breadcrumbs({ isOpen, className, area }: Props) {
  const { onDark } = useTheme();
  const Button = isOpen ? LowImpactButton : SubtleButton;

  return (
    <div className={clsx('ml-4 hidden md:flex md:items-center', className)}>
      <Button size="lg" rounding="square" external={false} href="/dashboard" label="Central" />
      {area ? (
        <>
          <Icon
            icon="caret-right"
            theme={onDark ? 'dark' : 'light'}
            className="mr-4 w-6 text-center text-charcoal"
          />

          <Button
            as={Popover.Button}
            className={clsx({
              'pointer-events-none': isOpen // Hack because we're rendering two Popover.Button components
            })}
            size="lg"
            rounding="square"
            suffixIcon={isOpen ? 'caret-up' : 'caret-down'}
            onDark={true}
            label={area}
          />
        </>
      ) : (
        ''
      )}
    </div>
  );
}
