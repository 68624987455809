import React, { createContext, useContext } from 'react';

export type Theme = 'light' | 'dark';

export const ThemeContext = createContext<Theme>('light');

export function ThemeProvider({ theme, children }: { theme: Theme; children: React.ReactNode }) {
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

export function useTheme() {
  const theme = useContext(ThemeContext);
  const onDark = theme === 'dark';

  return {
    onDark,
    backgroundColor: onDark ? 'bg-black' : 'bg-white',
    textColor: onDark ? 'text-white' : 'text-black'
  };
}
