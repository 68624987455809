import clsx from 'clsx';
import React, { RefObject } from 'react';
import { SubtleButton } from '../button/SubtleButton';
import { BodyTextSm } from '../typography/body/BodyText';

interface Props {
  className?: string;
  centered?: boolean;
  scrollToTop?: boolean;
  scrollPane?: RefObject<HTMLDivElement>;
}
export function FooterGlobal({
  className = '',
  centered = false,
  scrollToTop = true,
  scrollPane
}: Props) {
  function handleClick() {
    scrollPane?.current?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <footer
      className={clsx(
        className,
        'divide-y-2 divide-gray-90 dk:divide-gray-10 md:flex md:divide-none',
        centered
          ? 'items-center text-center md:flex-col md:space-y-2'
          : 'justify-between md:flex-row-reverse'
      )}
    >
      <div>
        {scrollToTop && (
          <SubtleButton
            rounding="square"
            size="sm"
            className={clsx('md:mb-auto')}
            label="Scroll to Top"
            prefixIcon="arrow-up"
            onClick={handleClick}
          />
        )}
      </div>
      <div className="mt-8 flex flex-col gap-2 pt-8 md:mt-0 md:pt-0">
        <div className="flex flex-col items-start gap-2 sm:block sm:gap-1">
          <SubtleButton
            rounding="square"
            size="sm"
            label="Nettwerk.com"
            suffixIcon="view-external"
            href="https://nettwerk.com/"
            external={true}
          />
          <SubtleButton rounding="square" size="sm" href="/support" label="Support" />
          <SubtleButton rounding="square" size="sm" href="/changelog" label="Changelog" />
        </div>
        <div className="flex flex-col items-start gap-2 sm:block sm:gap-1">
          <SubtleButton rounding="square" size="sm" href="/terms-of-use" label="Terms of Use" />
          <SubtleButton rounding="square" size="sm" href="/privacy-policy" label="Privacy" />
        </div>
        <BodyTextSm className="ml-2 mt-2 text-gray-30">
          Copyright © {new Date().getFullYear()} Nettwerk Music Group.
        </BodyTextSm>
      </div>
    </footer>
  );
}
