import clsx from 'clsx';
import { Button, ComposedButtonProps } from './Button';

export const highImpactButtonClassNames = [
  'bg-emerald text-black dk:bg-amethyst dk:text-white',
  'ring-offset-2',
  hover('bg-turquoise-90 text-black dk:bg-violet-30 dk:text-white'),
  focus('bg-emerald text-black outline-none dk:bg-amethyst dk:text-white'),
  active(
    'bg-gradient-to-tr from-emerald to-rose text-cyan-20 outline-0 text-shadow-glitch dk:from-violet-20 dk:to-sapphire dk:text-blue-80'
  ),
  disabled(
    'bg-turquoise-100 bg-none text-turquoise-50 opacity-80 shadow-none outline outline-2 outline-non-interactive-on-light text-shadow-none dk:bg-violet-10 dk:text-violet-50 dk:outline-non-interactive-on-dark'
  )
];

export const HighImpactButton = ({
  label,
  className,
  size = 'lg',
  ...props
}: ComposedButtonProps) => (
  <Button
    {...props}
    size={size}
    variant="high-impact"
    className={clsx(
      className,
      size === 'lg' ? 'active:shadow-glitch' : 'active:shadow-glitch-small',
      highImpactButtonClassNames
    )}
  >
    {label}
  </Button>
);
