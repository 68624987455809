import { Theme, ThemeProvider, useTheme } from '@/hooks/useTheme';
import clsx from 'clsx';
import React from 'react';

export const gradientBackgroundVariants = [
  'shade',
  'emerald',
  'sapphire',
  'violet',
  'rose',
  'agate'
];
export type GradientVariant = (typeof gradientBackgroundVariants)[number];

interface GradientBackgroundProps extends BackgroundProps {
  theme?: Theme;
}
interface BackgroundProps {
  variant: GradientVariant;
  children: React.ReactNode;
  reverseGradient?: boolean;
  className?: string;
}

function Background({
  variant,
  children,
  reverseGradient = false,
  className = ''
}: BackgroundProps) {
  const { backgroundColor, textColor } = useTheme();
  const gradientDirection = reverseGradient ? 'bg-gradient-to-t' : 'bg-gradient-to-b';

  return (
    <div
      className={clsx([
        'flex flex-1 overflow-y-hidden to-transparent',
        gradientDirection,
        backgroundColor,
        textColor,
        getGradientClass(variant),
        className
      ])}
    >
      {children}
    </div>
  );
}

export function GradientBackground({ theme = 'dark', ...rest }: GradientBackgroundProps) {
  return (
    <ThemeProvider theme={theme}>
      <Background {...rest} />
    </ThemeProvider>
  );
}

function getGradientClass(variant: GradientVariant): string {
  const classes: Record<GradientVariant, string> = {
    shade: 'from-black/40',
    emerald: 'from-emerald/40',
    sapphire: 'from-sapphire/40',
    violet: 'from-amethyst/40',
    rose: 'from-rose/40',
    agate: 'from-agate/40'
  };
  return classes[variant];
}
