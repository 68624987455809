import clsx from 'clsx';
import React from 'react';
import { Button, ComposedButtonProps } from './Button';
import Balancer from 'react-wrap-balancer';

export const subtleButtonClassNames = [
  'text-black dk:text-white',
  hover(
    'bg-interactive-on-light text-turquoise-20 underline dk:bg-interactive-on-dark dk:text-violet-60'
  ),
  focus('bg-transparent text-black no-underline outline-none dk:text-white'),
  active(
    'bg-transparent text-cyan-40 no-underline shadow-none outline-0 text-shadow-glitch dk:bg-transparent dk:text-blue-60'
  ),
  disabled(
    'bg-transparent text-gray-60 no-underline opacity-80 shadow-none text-shadow-none dk:bg-transparent dk:text-gray-40'
  )
];

export const SubtleButton = React.forwardRef<HTMLButtonElement, ComposedButtonProps>(
  ({ label, className, isActive = false, size = 'lg', ...props }, ref) => (
    <Button
      ref={ref}
      {...props}
      size={size}
      variant="subtle"
      className={clsx(
        className,
        isActive &&
          'bg-interactive-on-light text-turquoise-20 underline dk:bg-interactive-on-dark dk:text-violet-60',
        subtleButtonClassNames
      )}
    >
      <Balancer>{label}</Balancer>
    </Button>
  )
);
