import clsx from 'clsx';
import React from 'react';
import { BodyTextSm } from '../typography/body/BodyText';
import { withTheme } from '@/components/theme/withTheme';

export const badgeTypes = ['indicator', 'large', 'medium', 'circle'] as const;
export type BadgeType = (typeof badgeTypes)[number];
export const badgeVariants = [
  'user-selected',
  'default',
  'high-contrast',
  'high-impact',
  'high-impact-bright',
  'danger',
  'success',
  'info',
  'warning',
  'disabled',
  'count'
] as const;
export type BadgeVariant = (typeof badgeVariants)[number];

interface Props extends React.HTMLAttributes<HTMLElement> {
  onDark?: boolean;
  type: BadgeType;
  label?: React.ReactNode;
  className?: string;
}

interface BadgeProps extends Props {
  variant?: BadgeVariant;
  as?: keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>;
}

export const UserSelectedBadge = (props: Props) => <Badge {...props} variant="user-selected" />;
export const HighImpactBadge = (props: Props) => <Badge {...props} variant="high-impact" />;
export const HighImpactBrightBadge = (props: Props) => (
  <Badge {...props} variant="high-impact-bright" />
);
export const DefaultBadge = (props: Props) => <Badge {...props} variant="default" />;
export const HighContrastBadge = (props: Props) => <Badge {...props} variant="high-contrast" />;
export const DangerBadge = (props: Props) => <Badge {...props} variant="danger" />;
export const SuccessBadge = (props: Props) => <Badge {...props} variant="success" />;
export const InfoBadge = (props: Props) => <Badge {...props} variant="info" />;
export const WarningBadge = (props: Props) => <Badge {...props} variant="warning" />;
export const DisabledBadge = (props: Props) => <Badge {...props} variant="disabled" />;

export const Badge = withTheme<BadgeProps>(
  ({
    variant,
    as: Component = 'span',
    type,
    label,
    className,
    onDark = false,
    ...rest
  }: BadgeProps) => {
    const themeVariant = onDark ? 'dark' : 'light';
    return (
      <Component
        theme={themeVariant}
        className={clsx(
          className,
          'flex items-center justify-center font-bold',
          {
            'bg-blue-70 text-blue-10': variant === 'user-selected',
            'bg-violet-20 text-violet-100': variant === 'high-impact',
            'bg-turquoise-80 text-turquoise-10': variant === 'high-impact-bright',
            'bg-gray-80 text-gray-10 dk:bg-gray-20 dk:text-gray-100': variant === 'default',
            'bg-black text-white dk:bg-white dk:text-black': variant === 'high-contrast',
            'bg-red-60 text-red-10': variant === 'danger',
            'bg-green-80 text-green-10': variant === 'success',
            'bg-orange-70 text-orange-10': variant === 'warning',
            'bg-cyan-80 text-cyan-10': variant === 'info',
            'bg-gray-80 text-gray-10 opacity-30 dk:bg-gray-20 dk:text-gray-80 dk:opacity-60':
              variant === 'disabled'
          },
          {
            'h-2 w-2 rounded-full': type === 'indicator',
            'w-fit rounded px-2 py-1': type === 'large',
            'w-fit rounded px-1': type === 'medium',
            'h-6 w-6 rounded-full': type === 'circle'
          }
        )}
        {...rest}
      >
        {label ? <BodyTextSm isInline>{label}</BodyTextSm> : null}
      </Component>
    );
  }
);
